.studio {
  position: relative;

  h2 {
    font-size: 40px;
    font-weight: 800;
    text-transform: uppercase;
    color: $blackColor;

    span {
      color: #01aed8;
    }
  }

  p {
    line-height: 30px;
    font-size: 18px;
    color: $blackColor;
    text-transform: capitalize;
  }

  .tag-item {
    background-color: red;
    width: 100%;
    height: 30px;
    text-align: center;
    font-size: 13px;
    color: #fff;
    padding: 7px 30px;
    transform: rotate(-33deg);
    z-index: 1;
    position: absolute;
    left: -64px;
    top: 16px;
  }

  .btn {
    background-color: #01aed8;
    border-radius: 50px;
    padding: 12px 20px;
    color: #fff;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  figure {
    width: 600px;
  }

  .games_images {
    position: relative;

    figure {
      width: 200px !important;
      height: auto;
      border-radius: 16%;
      cursor: pointer;
      overflow: hidden;
    }
  }
}