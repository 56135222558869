@keyframes turn360 {
    100% {
        transform: rotate(360deg);
    }
}


@keyframes turn360reverse {
    100% {
        transform: rotate(-360deg);
    }
}