.faqs {
  position: relative;
  background-color: #f8f8f8;

  h3 {
    font-size: 35px;
    font-weight: 700;
    color: $blackColor;
  }

  .card {
    position: relative;
    padding: 15px 20px;
    cursor: pointer;
    border: none;

    svg {
      width: 40px;
    }

    .details {
      padding: 20px;
      font-size: 16px;
      font-family: inherit;
      text-transform: capitalize;
      font-weight: 300;
      line-height: 32px;
      background: #f5f5f5;
      border-radius: 5px;
      display: none;

      &.clicked {
        display: block !important;
      }
    }

    p {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 10px;

      span {
        font-size: 15px;
        font-weight: 700;
      }
    }
  }
}