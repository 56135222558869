.navbar {
    position: relative;
    border-bottom: 1px solid #e0e0e0;
    padding: 15px 0;

    &.active {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99999;
        background: #fff;
        box-shadow: 2px 2px 5px #d8d8d89d;
    }

    .brand-logo {
        width: 160px;
    }

    ul> {
        li {
            display: inline-block;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                top: 100%;
                left: 0;
                width: 0%;
                height: 2px;
                border-radius: 8px;
                background-color: #01AED8;
                opacity: 0;
                transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
            }

            &:not(:last-child) {
                padding-right: 30px;
            }

            a {
                font-size: 18px;
                color: $blackColor;
                transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
            }

            &.active {
                a {
                    font-weight: 800;
                    color: #01AED8 !important;
                }

                &::before {
                    opacity: 1 !important;
                    width: 50%;
                }
            }
        }
    }

    .btn_contact {
        background-color: $secondColor;
        color: #fff;
        padding: 10px 20px;
        text-transform: capitalize;
    }

    .btn_lang {
        background-color: #014c5f;
        color: #fff;
        padding: 10px 20px;
        text-transform: capitalize;
    }

    .burger-icon {
        width: 60px;
        height: 60px;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4px solid $mainColor;
    }

    .hamburger .line {
        width: 28px;
        height: 3px;
        background-color: #000000;
        display: block;
        margin: 7px auto;
        transition: all 0.3s ease-in-out;
    }

    .hamburger:hover {
        cursor: pointer;
    }

    #hamburger-11 {
        -webkit-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    #hamburger-11.is-active {
        animation: smallbig 0.6s forwards;
    }

    @keyframes smallbig {

        0%,
        100% {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
            transform: scale(1);
        }

        50% {
            -webkit-transform: scale(0);
            -ms-transform: scale(0);
            -o-transform: scale(0);
            transform: scale(0);
        }
    }

    #hamburger-11.is-active .line:nth-child(1),
    #hamburger-11.is-active .line:nth-child(2),
    #hamburger-11.is-active .line:nth-child(3) {
        -webkit-transition-delay: 0.2s;
        -o-transition-delay: 0.2s;
        transition-delay: 0.2s;
    }

    #hamburger-11.is-active .line:nth-child(2) {
        opacity: 0;
    }

    #hamburger-11.is-active .line:nth-child(1) {
        -webkit-transform: translateY(13px) rotate(45deg);
        -ms-transform: translateY(13px) rotate(45deg);
        -o-transform: translateY(13px) rotate(45deg);
        transform: translateY(13px) rotate(45deg);
    }

    #hamburger-11.is-active .line:nth-child(3) {
        -webkit-transform: translateY(-13px) rotate(-45deg);
        -ms-transform: translateY(-13px) rotate(-45deg);
        -o-transform: translateY(-13px) rotate(-45deg);
        transform: translateY(-13px) rotate(-45deg);
    }

    .mobile_menu {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background-color: #0db8fc;
        border-radius: 4px;
        box-shadow: 5px 5px 0px #f26f30;
        width: 95%;
        padding: 27px 30px;
        text-align: center;
        z-index: 999;
        top: -1000%;
        opacity: 0;
        transition: all .8s ease-in-out;

        &.show {
            top: 106%;
            opacity: 1;
        }

        ul {
            li {
                display: block;
                padding: 0;
                padding: 15px 0;


                &.active {
                    a {
                        color: #fff !important;
                    }
                }

                &::before {
                    top: 100%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #fff;
                }

                a {
                    text-transform: capitalize;
                    font-size: 18px;
                    color: #fff;
                }
            }
        }

        .btn_contact {
            margin-top: 15px;
        }
    }

    .burger-icon,
    .mobile_menu {
        display: none;
    }

}