.services {
  position: relative;
  background: #fafafa;

  h3 {
    font-size: 35px;
    font-weight: 700;
    color: $blackColor;

    span {
      color: $thirdColor;
    }
  }


  .btn {
    span {
      color: $thirdColor;
    }
  }

  .card {
    border: 0.2px solid #f1f1f1;
    padding: 20px 25px;
    z-index: 1;

    figure {
      width: 100%;
      height: 100px;

      img {
        width: 70px;
      }
    }

    h5 {
      font-size: 20px;
      color: $secondColor;
      font-weight: 700;
      text-transform: capitalize;
      height: 70px;
    }

    p {
      font-size: 15px;
      line-height: 30px;
      height: 360px;
      color: $blackColor;
      // -webkit-line-clamp: 4;
      // -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
    }
  }

  .circle_absolute {
    position: absolute;
    top: -14%;
    left: -8%;
    width: 400px;
    height: 400px;
    animation: turn360 10s linear infinite;
  }
}