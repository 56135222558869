.companies {
  figure {
    img {
      filter: grayscale(1);
      cursor: pointer;
      scale: 0.9;
      &:hover {
        filter: grayscale(0);
        scale: 1;
      }
    }
  }
}
