.pagenotfound {
    position: relative;
    padding: 300px 0;
    background: url(../../assets/images/404/404.png) top center no-repeat;

    h1 {
        font-size: 100px;
        font-weight: 100;
        font-size: 140px;
        -webkit-text-stroke: 15px $secondColor;
        text-stroke: 30px $secondColor;
    }

}