.banner {
    position: relative;
    background: url(../../assets/images/banner/bg.png) top/cover no-repeat;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 50px;
        left: -30px;
        width: 100px;
        height: 100px;
        background: url(../../assets/images/shapes/setting_icon.svg) top/cover no-repeat;
    }

    a {
        color: $blackColor;
    }
}