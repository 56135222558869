.clients {
  background-color: #f8f8f8;

  h3 {
    font-size: 35px;
    font-weight: 700;
    color: $blackColor;
    span {
      color: $secondColor;
    }
  }

  p {
    color: #212c4f;
  }

  .card {
    background-color: #fff;
    box-shadow: 2px 2px 10px #cdcdcd;
    padding: 20px 10px;
    border-radius: 8px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: -14%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 45px;
      height: 30px;
      background: #fff;
      box-shadow: 2px 2px 10px #cdcdcd;
      clip-path: polygon(50% 100%, 0 0, 100% 0);
    }

    .card-title {
      color: $secondColor;
      font-weight: 700;
      font-size: 25px;
    }

    .card-text {
      color: #212c4f;
      text-transform: capitalize;
      line-height: 30px;
      font-size: 13px;
      height: 180px;
      overflow: hidden;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }

  .clientImage {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
    margin: auto;
    display: flex;
  }

  .client_name {
    font-size: 20px;
    color: $secondColor;
    font-weight: 800;
    letter-spacing: 1px;
  }
}
