*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $mainFonts;
  transition: 0.5s ease-in-out;
}

html,
body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #EC6E32;
}

::-webkit-scrollbar-thumb {
  background: #0DB8FC;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

button {
  border: none;
  outline: none;
  // border-radius: 30px;
}

img {
  width: 100%;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.mb-30 {
  margin-bottom: 30px;
}


.default-padding {
  padding-top: 5rem;
  padding-bottom: 5rem;
}


.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 30px;
}