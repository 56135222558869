.header {
  background-color: $secondColor;
  padding: 10px 0;
  font-size: 14px;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}