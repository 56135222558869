.about {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: -40px;
    width: 450px;
    height: 450px;
    background: url(../../assets/images/about/patterns.svg) top / cover no-repeat;
    z-index: -1;
  }

  figure.mb-0 {
    width: 569px;
    float: right;
  }

  .btn {
    background-color: #ec6e32;
    border-radius: 50px;
    color: #fff;
    padding: 12px 20px;
    position: relative;
    z-index: 1;
  }

  h3 {
    font-size: 18px;
    color: $blackColor;

    span {
      color: #ec6e32;
    }
  }

  h2.section_title {
    font-size: 43px;
    font-weight: 800;
    color: #212c4f;
  }

  p {
    line-height: 30px;
    color: #212c4f;
  }

  .sequare {
    position: absolute;
    top: 32%;
    left: 8%;
    width: 60px;
    height: 60px;
    animation: turn360 5s linear infinite;
  }

  .rhombus {
    position: absolute;
    top: 66%;
    left: 9.5%;
    width: 60px;
    height: 60px;
    animation: turn360 5s linear infinite;
  }

  .circle {
    position: absolute;
    top: 36%;
    left: 48%;
    width: 40px;
    height: 40px;
  }

  .polygon {
    position: absolute;
    top: 88%;
    left: 35%;
    width: 40px;
    height: 40px;
    animation: turn360 8s linear infinite;
  }
}