.order2 {
    @media screen and (max-width: 991px) {
        order: 2;
    }
}

.header {
    @media screen and (max-width: 999px) {
        display: none;
    }
}

.navbar {
    .hide_in_mobile {
        @media screen and (max-width: 999px) {
            display: none;
        }
    }

    .burger-icon {
        @media screen and (max-width: 999px) {
            display: flex;
        }
    }

    .mobile_menu {
        @media screen and (max-width: 999px) {
            display: block;
        }
    }
}

.hero {
    @media screen and (max-width: 767px) {
        text-align: center;
    }

    .d-flex.align-items-center.gap-5 {
        @media screen and (max-width: 767px) {
            justify-content: center;
        }
    }

    .hero_bg {
        @media screen and (max-width: 767px) {
            // margin-bottom: 20px;
        }
    }

    .rhombus {
        right: 10% !important;
        top: 37% !important;
        left: auto !important;
    }

    h1.hero_title {
        @media screen and (max-width: 767px) {
            font-size: 3.5em;
            line-height: 69px;
        }

        @media screen and (max-width: 1199px) {
            font-size: 3em;
            line-height: 69px;
        }

        @media screen and (max-width: 991px) {
            font-size: 39px;
            line-height: 56px;
        }
    }

    .hero_sub {
        @media screen and (max-width: 767px) {
            line-height: 29px;
        }

        @media screen and (max-width: 1199px) {
            font-size: 15px;
            line-height: 28px;
        }

        @media screen and (max-width: 991px) {
            font-size: 12px;
            line-height: 24px;
        }
    }

    .btn_started {
        @media screen and (max-width: 991px) {
            font-size: 10px;
        }
    }

    .arrow {
        @media screen and (max-width: 991px) {
            position: absolute;
            left: 40%;
            bottom: -41%;
            width: 200px;
            height: 200px;
            z-index: -1;
        }

        @media screen and (max-width: 900px) {
            display: none;
        }
    }

}

.about {
    @media screen and (max-width: 991px) {
        text-align: center;
    }
}

.services {
    .circle_absolute {
        @media screen and (max-width: 991px) {
            display: none;
        }
    }
}

.studio {
    @media screen and (max-width: 991px) {
        text-align: center;
    }

    .games_images.d-flex.align-items-center.flex-wrap.gap-2 {
        @media screen and (max-width: 991px) {
            justify-content: center;
        }
    }

    .btn {
        @media screen and (max-width: 991px) {
            margin: auto;
        }
    }

    figure {
        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }
}

footer {
    @media screen and (max-width: 991px) {
        .ps-sp {
            padding: calc(var(--bs-gutter-x) * 0.5);
        }
    }
}

.apps {
    h2 {
        @media screen and (max-width: 991px) {
            text-align: center;
            font-size: 20px;
        }
    }

    p {
        @media screen and (max-width: 991px) {
            text-align: center;
            font-size: 15px;
        }
    }

    figure.w-600 {
        @media screen and (max-width: 991px) {
            width: 100% !important;
        }
    }
}