.hero {
  padding: 40px 0;
  position: relative;

  h1.hero_title {
    font-size: 4.5em;
    font-weight: 800;
    line-height: 90px;
    text-transform: capitalize;
    color: $blackColor;
  }

  .hero_sub {
    line-height: 34px;
    color: $blackColor;
  }

  .btn_started {
    background-color: #01aed8;
    border-radius: 50px;
    padding: 12px 20px;
  }

  .btn_video_play {
    background-color: transparent;

    span {
      color: #ec6e32;
    }
  }

  .hero_bg {

    // .bg_hero {
    //   position: relative;

    //   &::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     background: #0DB8FC;
    //     width: 100%;
    //     height: 100%;
    //     z-index: 0;
    //     border-radius: 51% 51% 77% 77%;
    //   }

    //   &::after {
    //     content: "";
    //     position: absolute;
    //     background: #f26f30;
    //     width: 90%;
    //     height: 90%;
    //     z-index: 0;
    //     border-radius: 64% 56% 87% 61%;
    //     left: 50%;
    //     right: 50%;
    //     top: 50%;
    //     transform: translate(-50%, -50%) rotate(-90deg);
    //   }

    //   img {
    //     z-index: 1;
    //     position: relative;
    //   }
    // }

    &::before {
      content: "";
      position: absolute;
      top: 40px;
      right: 5px;
      width: 30px;
      height: 30px;
      background: url("../../assets/images/shapes/circle.svg") top/cover no-repeat;
    }

    &::after {
      content: "";
      position: absolute;
      top: 40px;
      left: 5px;
      width: 30px;
      height: 30px;
      background: url("../../assets/images/shapes/polygon.svg") top/cover no-repeat;
      animation: turn360 15s linear infinite;
    }
  }

  .sequare {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 100%;
    bottom: 0;
    left: 60px;
    animation: turn360 5s linear infinite;
  }

  .rhombus {
    position: absolute;
    left: 10%;
    top: 8%;
    width: 75px;
    height: 75px;
    z-index: -1;
    animation: turn360 5s linear infinite;
  }

  .arrow {
    position: absolute;
    left: 0%;
    top: 50px;
    transform: translateX(-50%);
    width: 300px;
    height: 300px;
    z-index: -1;
  }
}