.statistics {
  padding: 100px 0;
  background: url(../../assets/images/shapes/wave_bg.svg) top/cover no-repeat;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $thirdColor;
    z-index: -1;
  }

  h3 {
    font-size: 50px;
    font-weight: 700;
    color: $blackColor;

    span {
      color: #ff6464;
    }
  }

  p {
    color: $blackColor;
  }

  .card {
    background-color: transparent !important;

    .card-title,
    .card-number {
      color: #fff;
    }

    .card-title {
      font-size: 30px;
    }

    .card-number {
      font-size: 60px;
      font-weight: 700;
    }
  }
}
